// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import { CollectionPreferences, Link, StatusIndicator } from '@cloudscape-design/components';
import { addColumnSortLabels } from '../../common/labels';

export const COLUMN_DEFINITIONS = addColumnSortLabels([
  {
    id: 'automation_execution_id',
    sortingField: 'Id',
    header: 'Execution Id',
    cell: item => item.automation_execution_id,
    minWidth: 300,
  },
  {
    id: 'document_name',
    sortingField: 'Document',
    header: 'Document',
    cell: item => item.document_name,
    minWidth: 200,
  },
  {
    id: 'automation_execution_status',
    sortingField: 'Status',
    header: 'Status',
    cell: item => {
        switch(item.automation_execution_status) {
          case 'Success':
            return <StatusIndicator  type='success'>Success</StatusIndicator>;
          case 'Failed':
            return <StatusIndicator  type='error'>Failed</StatusIndicator>;
          default:
            return <StatusIndicator  type='info'>{item.automation_execution_status}</StatusIndicator>;
        }
    },
    minWidth: 200,
  },
  {
    id: 'execution_start_time',
    sortingField: 'Start Time',
    header: 'Start Time',
    cell: item =>  {
      return item.execution_start_time? (item.execution_start_time.length > 19 ? item.execution_start_time.substring(0, 19): item.execution_start_time): "";
    },
    minWidth: 200,
  },
  {
    id: 'execution_end_time',
    sortingField: 'End Time',
    header: 'End Time',
    cell: item =>  {
      return item.execution_end_time? (item.execution_end_time.length > 19 ? item.execution_end_time.substring(0, 19): item.execution_end_time): ""
    },
    minWidth: 200,
  }
]);

const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Main runbook execution properties',
    options: [
      { id: 'automation_execution_id', label: 'Id', editable: true },
      { id: 'document_name', label: 'Document', editable: true },
      { id: 'automation_execution_status', label: 'Status', editable: true },
      { id: 'execution_start_time', label: 'Start Time', editable: true },
      { id: 'execution_end_time', label: 'End Time', editable: true }
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Executions' },
  { value: 25, label: '25 Executions' },
  { value: 50, label: '50 Executions' }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: ['automation_execution_id', 'document_name', 'automation_execution_status', 'execution_start_time', 'execution_end_time'],
  wrapLines: false,
};

export const FILTERING_PROPERTIES = [
  {
    propertyLabel: 'Id',
    key: 'automation_execution_id',
    groupValuesLabel: 'Id values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Document',
    key: 'document_name',
    groupValuesLabel: 'Document values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status',
    key: 'automation_execution_status',
    groupValuesLabel: 'Status values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Start Time',
    key: 'execution_start_time',
    groupValuesLabel: 'Start Time values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'End Time',
    key: 'execution_end_time',
    groupValuesLabel: 'End Time values',
    operators: [':', '!:', '=', '!='],
  }

];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: text => `Use: "${text}"`,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);
