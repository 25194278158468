// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React, { useEffect, useState } from 'react';
import PropertyFilter from "@cloudscape-design/components/property-filter";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { COLUMN_DEFINITIONS, FILTERING_PROPERTIES, PROPERTY_FILTERING_I18N_CONSTANTS, DEFAULT_PREFERENCES, Preferences } from './research-payments-table-config';
import { AUDIT_COLUMN_DEFINITIONS, AUDIT_FILTERING_PROPERTIES, AUDIT_PROPERTY_FILTERING_I18N_CONSTANTS, AUDIT_DEFAULT_PREFERENCES, AuditPreferences } from './research-payments-audit-table-config';
import {
  Box,
  BreadcrumbGroup,
  Button,
  Grid,
  Input,
  HelpPanel,
  SplitPanel,
  Pagination,
  SpaceBetween,
  Table, Flashbar,
} from '@cloudscape-design/components';
import { CustomAppLayout } from '../common/app-layout';
import { Navigation } from '../common/navigation';
import { Notifications } from '../common/notifications';
import { TableEmptyState, TableHeader, TableNoMatchState } from '../common/table-components';

import { paginationLabels, distributionSelectionLabels } from '../../common/labels';
import { getFilterCounterText } from '../../common/tableCounterStrings';
import '../../styles/base.scss';
import { useColumnWidths } from '../common/use-column-widths';
import { useLocalStorage } from '../../common/localStorage';

import { useDispatch, useSelector } from "react-redux";
import { storePayment } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import {
  getBooks,
  searchPaymentsByCustomer,
  searchPaymentsByCustomerReference,
  searchPaymentsByPaymentId
} from "../../data";
import {v4 as uuid4} from "uuid";

import {getPanelContent, splitPanelI18nStrings, useSplitPanel} from './research-payments-split-panel';

export const resourcesBreadcrumbs = [
  {
    text: 'Research',
    href: '/Research',
  }
];

export const Breadcrumbs = () => (
    <BreadcrumbGroup items={resourcesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export const FullPageHeader = ({
                                 resourceName = 'Payments',
                                 createButtonText = 'Create Payment',
                                 ...props
                               }) => {
  const isOnlyOneSelected = props.selectedItems.length === 1;

  const history = useHistory();

  const onOpenClick = () => {
    history.push("/Payment");
  }

  return (
      <TableHeader
          variant="awsui-h1-sticky"
          title={resourceName}
          actionButtons={
            <SpaceBetween size="xs" direction="horizontal">
              {/*<Button onClick={onOpenClick} disabled={!isOnlyOneSelected}>View details</Button>*/}
            </SpaceBetween>
          }
          {...props}
      />
  );
};

export const ToolsContent = () => (
    <HelpPanel
        header={<h2>Research Payments</h2>}
        footer={
          <>
          </>
        }
    >
      <p>
        Research Payments.
      </p>
    </HelpPanel>
);

function ResearchPaymentsTableView() {

  const dispatch = useDispatch();
  const history = useHistory();

  const token = useSelector( (state) => {
    return state.reducerState.token
  });

  const payment = useSelector( (state) => {
    return state.reducerState.payment
  });

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();

  const [customer, setCustomer] = useState('');
  const [reference, setReference] = useState('');
  const [id, setId] = useState('');

  const [columnDefinitions, saveWidths] = useColumnWidths('React-ResearchPaymentsTable-Widths', COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('React-ResearchPaymentsTable-Preferences', DEFAULT_PREFERENCES);

  const [auditColumnDefinitions, auditSaveWidths] = useColumnWidths('React-ResearchPaymentsAuditTable-Widths', AUDIT_COLUMN_DEFINITIONS);
  const [auditPreferences, setAuditPreferences] = useLocalStorage('React-ResearchPaymentsAuditTable-Preferences', AUDIT_DEFAULT_PREFERENCES);

  const [notifications, setNotifications] = useState([]);

  const [toolsOpen, setToolsOpen] = useState(false);

  const addNotification = (message) => {
    const list = []
    for (let notification of notifications) {
      list.push(notification)
    }
    list.push({
      type: 'error',
      content: message,
      statusIconAriaLabel: 'error',
      dismissLabel: 'Dismiss all messages',
      dismissible: true,
      onDismiss: () => setNotifications([]),
      id: uuid4(),
    });
    setNotifications(list);
  };

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
      rows,
      {
        propertyFiltering: {
          filteringProperties: FILTERING_PROPERTIES,
          empty: <TableEmptyState resourceName="No Payment Found. Please enter search criteria above and initiate search." />,
          noMatch: (
              <TableNoMatchState
                  onClearFilter={() => {
                    actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                  }}
              />
          ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        selection: {},
      }
  );

  const { auditItems, auditActions, auditFilteredItemsCount, auditCollectionProps, auditPaginationProps, auditPropertyFilterProps } = useCollection(
      selectedRows,
      {
        propertyFiltering: {
          filteringProperties: AUDIT_FILTERING_PROPERTIES,
          empty: <TableEmptyState resourceName="" />,
          noMatch: (
              <TableNoMatchState
                  onClearFilter={() => {
                    actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                  }}
              />
          ),
        },
        pagination: { pageSize: auditPreferences.pageSize },
        sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        selection: {},
      }
  );

  const onSearchClick = () => {
    if (id != "") {
      invokeSearchPaymentsById();
    }
    else if (customer != "" && reference != "") {
      invokeSearchPaymentsByCustomerReference();
    }
    else if (customer != "") {
      invokeSearchPaymentsByCustomer()
    }
    else {
      addNotification("Please populate 'Customer', 'Customer and Reference' or 'Payment ID' to initiate search.")
    }
  }

  const invokeSearchPaymentsByCustomer = async () => {

    try {

      await searchPaymentsByCustomer(token, customer).then(
          (result) => {
            setRows(result);
          });

      await Promise.resolve();

    }
    catch (err) {
      console.log("Got error message while invoking invokeSearchPaymentsByCustomer : " + err.toString());
    }
  }

  const invokeSearchPaymentsByCustomerReference = async () => {

    try {

      await searchPaymentsByCustomerReference(token, customer, reference).then(
          (result) => {
            setRows(result);
          });

      await Promise.resolve();

    }
    catch (err) {
      console.log("Got error message while invoking invokeSearchPaymentsByCustomerReference : " + err.toString());
    }
  }

  const invokeSearchPaymentsById = async () => {

    try {

      await searchPaymentsByPaymentId(token, id).then(
          (result) => {
            setRows(result);
          });

      await Promise.resolve();

    }
    catch (err) {
      console.log("Got error message while invoking invokeSearchPaymentsById : " + err.toString());
    }
  }

  const selectRow = (row) => {
    setSelectedRow(row)
    dispatch(storePayment(row));
  }

  useEffect( () => {

  }, []);

  const { splitPanelOpen, onSplitPanelToggle, splitPanelSize, onSplitPanelResize } = useSplitPanel(
      selectedRows
  );

  const { header: panelHeader, body: panelBody } = getPanelContent(selectedRows, auditColumnDefinitions, auditPreferences, auditSaveWidths);

  return (
    <CustomAppLayout
      navigation={<Navigation activeHref="/Books" />}
      navigationOpen={true}
      notifications={<Notifications successNotification={false} />}
      breadcrumbs={<Breadcrumbs />}
      splitPanelOpen={splitPanelOpen}
      onSplitPanelToggle={onSplitPanelToggle}
      splitPanelSize={splitPanelSize}
      onSplitPanelResize={onSplitPanelResize}
      splitPanel={<SplitPanel closeBehavior="hide" hidePreferencesButton={false} i18nStrings={splitPanelI18nStrings} header={panelHeader}>{panelBody}</SplitPanel>}
      content={
        <div>
          <br/>
          <Grid gridDefinition={[{colspan: 3, offset: 0}, {colspan: 3, offset: 0}, {colspan: 3, offset: 0}, {colspan: 3, offset: 0}]}>

            <Input
                onChange={({ detail }) => setCustomer(detail.value)}
                value={customer}
                placeholder="Customer"
            />
            <Input
                onChange={({ detail }) => setReference(detail.value)}
                value={reference}
                placeholder="Reference"
            />
            <Input
                onChange={({ detail }) => setId(detail.value)}
                value={id}
                placeholder="Payment ID"
            />
            <Button variant="primary" onClick={onSearchClick} >Search</Button>
          </Grid>
          <Box margin={{ top: 's', bottom: 's' }} padding={{ top: 's',  horizontal: 's' }}>
            <SpaceBetween size="s">
              <div>
                <Flashbar items={notifications} />
              </div>
            </SpaceBetween>
          </Box>
          <Table
              {...collectionProps}
              items={items}
              columnDefinitions={columnDefinitions}
              visibleColumns={preferences.visibleContent}
              ariaLabels={distributionSelectionLabels}
              selectionType="single"
              variant="container"
              stickyHeader={true}
              resizableColumns={true}
              wrapLines={preferences.wrapLines}
              onColumnWidthsChange={saveWidths}
              header={
                <FullPageHeader
                    selectedItems={selectedRows}
                    totalItems={rows}
                    serverSide={false}
                />
              }
              loadingText="Loading books"
              filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                    {...propertyFilterProps}
                    countText={getFilterCounterText(filteredItemsCount)}
                    expandToViewport={true}
                />
              }
              pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
              preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
              selectedItems={selectedRows}
              onSelectionChange={evt => {setSelectedRows(evt.detail.selectedItems); selectRow(evt.detail.selectedItems[0])}}
          />
        </div>
      }
      contentType="table"
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      stickyNotifications={true}
    />
  );
}

export default ResearchPaymentsTableView;