// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React, {useState} from 'react';

import '../common/styles.css';
import '../../styles/base.scss';
import {
  Box,
  Button, Container,
  Flashbar, Form, FormField,
  Grid, Header,
  HelpPanel,
  SpaceBetween
} from "@cloudscape-design/components";
import {Navigation} from "../common/navigation";
import {CustomAppLayout} from "../common/app-layout";
import Input from "@cloudscape-design/components/input";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import {v4 as uuid4} from "uuid";
import { SessionContext } from '../contexts/SessionContext';

export default class ConfirmSignUpView extends React.Component {
  render() {
    return (
        <CustomAppLayout
            navigation={<Navigation activeHref="/"/>}
            navigationOpen={false}
            content={<ConfirmSignUpContent />}
            contentType="default"
            tools={<ToolsContent />}
            toolsHide={false}
            // labels={appLayoutNavigationLabels}
        />
    );
  }
}

export const ToolsContent = () => (
    <HelpPanel
        header={<h2>Payment Operations</h2>}
        footer={
          <>
          </>
        }
    >
      <p>
        The Payment Operations Portal..
      </p>
    </HelpPanel>
);

// The content in the main content area of the App layout
export function ConfirmSignUpContent() {

  const history = useHistory();

  const [user, setUser] = React.useState("");
  const [code, setCode] = React.useState("");
  const [notifications, setNotifications] = useState([]);

  const [userError, setUserError] = React.useState("");
  const [codeError, setCodeError] = React.useState("");

  const session = React.useContext(SessionContext);

  const addNotification = (message) => {
    const list = []
    for (let notification of notifications) {
      list.push(notification)
    }
    list.push({
      type: 'error',
      content: message,
      statusIconAriaLabel: 'error',
      dismissLabel: 'Dismiss all messages',
      dismissible: true,
      onDismiss: () => setNotifications([]),
      id: uuid4(),
    });
    setNotifications(list);
  };

  const validateForm = () =>  {

    setUserError("");
    setCodeError("");

    if (user == "") {
      setUserError("User is required.")
    }

    if (code == "") {
      setCodeError("Code is required.")
    }

    if (userError != "" || codeError != "") {
      return false;
    }
    else {
      return true;
    }
  }

  const confirmSignup = () => {
    try {
      Auth.confirmSignUp(user, code).then((result) => {
        history.push("/Login");
      }).catch (error => {
        console.log("Got error in confirm signup function");
        console.log(error);
        addNotification("Error during confirm sign up.")
      });
    } catch (error) {
      console.log("Got error in confirm signup function");
      console.log(error);
    }
  }

  return (
      <div>
        <Box margin={{ bottom: 'l' }}>
          <div className="back_ground_black">
            <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
              <Grid
                  gridDefinition={[
                    { colspan: { xl: 6, l: 5, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
                  ]}
              >
                <div className="text_white">
                  <SpaceBetween size="xl">
                    <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                      Payment Operations
                    </Box>
                    <Box variant="h3" fontWeight="bold">
                      <span className="text_white">
                        The Payment Operations Portal
                      </span>
                    </Box>
                    <Box>
                      <Button disabled="true" href="/Analytics" variant="primary">Open Dashboard</Button>
                    </Box>
                  </SpaceBetween>
                </div>

              </Grid>
            </Box>
          </div>
          <div className="border_black">
            <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
              <Grid
                  gridDefinition={[
                    { colspan: 6, offset: 3 }
                  ]}
              >
                <div>
                  <Container
                      header={
                        <Header
                            variant="h2"
                            description={session.user ? session.user.username : ""}
                            actions={
                              <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={({ detail }) => confirmSignup()}>Confirm</Button>
                              </SpaceBetween>
                            }
                        >Registration Confirmation</Header>
                      }
                  >
                    <form><Form>
                      <SpaceBetween direction="vertical" size="xs">
                        <FormField
                            stretch="true"
                            label="Username"
                            errorText={userError}
                        >
                          <Input
                              value={user}
                              onChange={event =>
                                  setUser(event.detail.value)
                              }
                          />
                        </FormField>
                        <FormField
                            stretch="true"
                            label="Code"
                            errorText={codeError}
                        >
                          <Input
                              value={code}
                              onChange={event => setCode(event.detail.value)}
                          />
                        </FormField>
                      </SpaceBetween>
                    </Form></form>

                  </Container>
                  <Box margin={{ top: 's', bottom: 'xl' }} padding={{ top: 'xl',  horizontal: 'xl' }}>
                    <SpaceBetween size="xl">
                      <div>
                        <Flashbar items={notifications} />
                      </div>

                    </SpaceBetween>
                  </Box>
                </div>

              </Grid>
            </Box>
            <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
            </Box>
          </div>
        </Box>

      </div>
  );
}



