// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import {SideNavigation} from "@cloudscape-design/components";
import React from "react";
import {useHistory} from "react-router-dom";

export const navHeader = { text: 'Payment Operations', href: '/' };
export const navItems = [
  {
    type: 'section',
    text: 'Multi Region',
    items: [
      { type: 'link', text: 'Summary', href: '/Summary' },
      { type: 'link', text: 'Runbooks', href: '/Runbooks' },
    ],
  },
  {
    type: 'section',
    text: 'Operations',
    items: [
      { type: 'link', text: 'Research', href: '/Research' }
    ],
  }
];

const defaultOnFollowHandler = ev => {
  console.log("Text : " + ev.detail.text)
  // ev.preventDefault();
};

export function Navigation({
                             activeHref,
                             header = navHeader,
                             items = navItems,
                             onFollowHandler = defaultOnFollowHandler,
                           }) {

  const history = useHistory();

  const defaultOnFollowHandler = ev => {
    ev.preventDefault();
    history.push("/" + ev.detail.text.replace(" ", ""));
  };

  return <SideNavigation items={items} header={header} activeHref={activeHref} onFollow={defaultOnFollowHandler} />;
}