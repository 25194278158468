// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import { AppLayout } from "@cloudscape-design/components";
import { appLayoutLabels } from "../../common/labels";
import React from "react";


export function CustomAppLayout(props) {
  return (
      <AppLayout
          {...props}
          headerSelector="#header"
          ariaLabels={appLayoutLabels}
          onNavigationChange={event => {
            if (props.onNavigationChange) {
              props.onNavigationChange(event);
            }
          }}
          onToolsChange={event => {
            if (props.onToolsChange) {
              props.onToolsChange(event);
            }
          }}
      />
  );
}