// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React, {useEffect, useState} from "react";
import {
  Grid, Table
} from '@cloudscape-design/components';
import initialize_image from "./initialize.png";
import receipt_image from "./receipt.png";
import validation_image from "./validation.png";
import enrichment_image from "./enrichment.png";
import acceptance_image from "./acceptance.png";
import preprocessing_image from "./preprocessing.png";
import processing_image from "./processing.png";
import notification_image from "./notification.png";
import settlement_image from "./settlement.png";
import completion_image from "./completion.png";
import receipt_failure_image from "./receipt-failure.png";
import validation_failure_image from "./validation-failure.png";
import enrichment_failure_image from "./enrichment-failure.png";
import acceptance_failure_image from "./acceptance-failure.png";
import preprocessing_failure_image from "./preprocessing-failure.png";
import processing_failure_image from "./processing-failure.png";
import notification_failure_image from "./notification-failure.png";
import settlement_failure_image from "./settlement-failure.png";
import completion_failure_image from "./completion-failure.png";
import Box from "@cloudscape-design/components/box";

export const EMPTY_PANEL_CONTENT = {
  header: 'No payments transaction selected',
  body: 'Select a payment transaction to see details.'
};

export const useSplitPanel = selectedRows => {
  const [splitPanelSize, setSplitPanelSize] = useState(400);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [hasManuallyClosedOnce, setHasManuallyClosedOnce] = useState(false);

  const onSplitPanelResize = ({ detail: { size } }) => {
    setSplitPanelSize(size);
  };

  const onSplitPanelToggle = ({ detail: { open } }) => {
    setSplitPanelOpen(open);

    if (!open) {
      setHasManuallyClosedOnce(true);
    }
  };

  useEffect(() => {
    if (selectedRows.length && !hasManuallyClosedOnce) {
      setSplitPanelOpen(true);
    }
  }, [selectedRows.length, hasManuallyClosedOnce]);

  return {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelSize,
    onSplitPanelResize,
  };
};

export const getPanelContent = (items, columnDefinitions, preferences, saveWidths) => {
    return getPanelContentSingle(items, columnDefinitions, preferences, saveWidths);
};

export const getPanelContentSingle = (items, columnDefinitions, preferences, saveWidths) => {

  if (!items.length) {
    return EMPTY_PANEL_CONTENT;
  }

  const item = items[0];

  return {
    header: (item.customer + " : " + item.account + " : " + item.status),
    body: (
        <div>
          <Grid gridDefinition={[{colspan: 6, offset: 2 }]}>
            {renderImage(item.audit_status)}
          </Grid>
          <Grid gridDefinition={[{colspan: 3, offset: 3  }, {colspan: 3, offset: 0 }]}>
            <div className="awsui-util-spacing-v-s">
              <div>
                <Box variant="awsui-key-label">Customer Reference</Box>
                <div>{item.customer_reference}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Payment Id</Box>
                <div>{item.payment_id}</div>
              </div>
              <div>
                <Box variant="awsui-key-label">Payment Type</Box>
                <div>{item.payment_type}</div>
              </div>
            </div>
            <div>
              <Box variant="awsui-key-label">Beneficiary Info</Box>
              <div>{item.beneficiary_name}</div>
              <div>{item.beneficiary_address}</div>
              <div>{item.beneficiary_city}, {item.beneficiary_state}, {item.beneficiary_zip}</div>
            </div>
          </Grid>
          <Grid gridDefinition={[{colspan: 6, offset: 2 }]}>
            <div className="border_black">
            <Table
                items={items[0].audit?items[0].audit:[]}
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                variant="borderless"
                resizableColumns={true}
                wrapLines={preferences.wrapLines}
                onColumnWidthsChange={saveWidths}
            />
            </div>
          </Grid>
        </div>)
  };
};

export const renderImage = (status) => {
  if (status == "initialize") {
    return <img src={initialize_image} width="800" height="150"/>
  }
  else if (status == "receipt") {
    return <img src={receipt_image} width="800" height="150"/>
  }
  else if (status == "validation") {
    return <img src={validation_image} width="800" height="150"/>
  }
  else if (status == "enrichment") {
    return <img src={enrichment_image} width="800" height="150"/>
  }
  else if (status == "acceptance") {
    return <img src={acceptance_image} width="800" height="150"/>
  }
  else if (status == "preprocessing") {
    return <img src={preprocessing_image} width="800" height="150"/>
  }
  else if (status == "processing") {
    return <img src={processing_image} width="800" height="150"/>
  }
  else if (status == "notification") {
    return <img src={notification_image} width="800" height="150"/>
  }
  else if (status == "settlement") {
    return <img src={settlement_image} width="800" height="150"/>
  }
  else if (status == "completion") {
    return <img src={completion_image} width="800" height="150"/>
  }
  else if (status == "receipt-failure") {
    return <img src={receipt_failure_image} width="800" height="150"/>
  }
  else if (status == "validation-failure") {
    return <img src={validation_failure_image} width="800" height="150"/>
  }
  else if (status == "enrichment-failure") {
    return <img src={enrichment_failure_image} width="800" height="150"/>
  }
  else if (status == "acceptance-failure") {
    return <img src={acceptance_failure_image} width="800" height="150"/>
  }
  else if (status == "preprocessing-failure") {
    return <img src={preprocessing_failure_image} width="800" height="150"/>
  }
  else if (status == "processing-failure") {
    return <img src={processing_failure_image} width="800" height="150"/>
  }
  else if (status == "notification-failure") {
    return <img src={notification_failure_image} width="800" height="150"/>
  }
  else if (status == "settlement-failure") {
    return <img src={settlement_failure_image} width="800" height="150"/>
  }
  else if (status == "completion-failure") {
    return <img src={completion_failure_image} width="800" height="150"/>
  }
}

export const splitPanelI18nStrings = {
  preferencesTitle: 'Split panel preferences',
  preferencesPositionLabel: 'Split panel position',
  preferencesPositionDescription: 'Choose the default split panel position for the service.',
  preferencesPositionSide: 'Side',
  preferencesPositionBottom: 'Bottom',
  preferencesConfirm: 'Confirm',
  preferencesCancel: 'Cancel',
  closeButtonAriaLabel: 'Close panel',
  openButtonAriaLabel: 'Open panel',
  resizeHandleAriaLabel: 'Resize split panel',
};