// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

// @ts-ignore
import ApiHandler, { ApiMethod } from '../common/api'

import {IAppControls, ICustomerFile, IExecution, IExecutionDetail, IPayment } from '../interfaces'

import {DASHBOARD_ENDPOINTS, OPERATIONS_API_ENDPOINT } from '../config'

export const dashboard_api = new ApiHandler(DASHBOARD_ENDPOINTS.Endpoint, DASHBOARD_ENDPOINTS.ApiKey, DASHBOARD_ENDPOINTS.Resources);

export const operations_api = new ApiHandler(OPERATIONS_API_ENDPOINT.Endpoint, OPERATIONS_API_ENDPOINT.ApiKey, OPERATIONS_API_ENDPOINT.Resources);

export const getAppControls = (token: string, user_params?:any) => operations_api.get_authorized_resource<IAppControls>(
    "get-app-controls", token, ApiMethod.GET, null, [])

export const executeRunbook = (token: string, document:string, user_params?:any) => operations_api.get_authorized_resource<any>(
    "execute-runbook", token, ApiMethod.POST, {document: document}, [])

export const getRunbookExecutions = (token: string, user_params?:any) => operations_api.get_authorized_resource<IExecution[]>(
    "get-runbook-executions", token, ApiMethod.GET, null, [])

export const getRunbookExecutionDetails = (token: string, id: string, user_params?:any) => operations_api.get_authorized_resource<IExecutionDetail>(
    "get-runbook-execution-detail", token, ApiMethod.GET, null, [{key:"id", value:id}])

export const searchPaymentsByCustomer = (token: string, customer: string, user_params?:any) => operations_api.get_authorized_resource<IPayment[]>(
    "search-payments-by-customer", token, ApiMethod.GET, null, [{key:"customer", value:customer}])

export const searchPaymentsByCustomerReference = (token: string, customer: string, reference: string, user_params?:any) => operations_api.get_authorized_resource<IPayment[]>(
    "search-payments-by-customer-reference", token, ApiMethod.GET, null, [{key:"customer", value:customer},{key:"reference", value:reference}])

export const searchPaymentsByPaymentId = (token: string, id: string, user_params?:any) => operations_api.get_authorized_resource<IPayment[]>(
    "search-payment-by-id", token, ApiMethod.GET, null, [{key:"id", value:id}])

export const getCustomerInputFiles = (token: string, user_params?:any) => dashboard_api.get_authorized_resource<ICustomerFile[]>(
    "get-customer-input-files", token, ApiMethod.GET, null, [])

export const getCustomerOutputFiles = (token: string, user_params?:any) => dashboard_api.get_authorized_resource<ICustomerFile[]>(
    "get-customer-output-files", token, ApiMethod.GET, null, [])

export const getFileContent = (token: string, name: string, user_params?:any) => dashboard_api.get_authorized_resource<ICustomerFile[]>(
    "get-file-content", token, ApiMethod.GET, null, [{key:"name", value:name}])

export const putFileContent = (token: string, data: any, user_params?:any) => dashboard_api.post_form_data<any>(
    "put-file-content", token, ApiMethod.POST, data, [])

export const updatePassword = (token: string, pool: string, password: string, user_params?:any) => dashboard_api.get_authorized_resource<any>(
    "update-password", token, ApiMethod.POST, {pool: pool, password: password}, [])