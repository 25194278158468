// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React, {useState} from 'react';

import '../common/styles.css';
import '../../styles/base.scss';
import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Grid,
  Header,
  HelpPanel,
  SpaceBetween
} from "@cloudscape-design/components";
import {Navigation} from "../common/navigation";
import {CustomAppLayout} from "../common/app-layout";
import Input from "@cloudscape-design/components/input";
import {Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {v4 as uuid4} from "uuid";
import { useParams } from 'react-router-dom';

export default class SignUpView extends React.Component {
  render() {
    return (
        <CustomAppLayout
            navigation={<Navigation activeHref="/"/>}
            navigationOpen={false}
            content={<SignUpContent />}
            contentType="default"
            tools={<ToolsContent />}
            toolsHide={false}
            // labels={appLayoutNavigationLabels}
        />
    );
  }
}

export const ToolsContent = () => (
    <HelpPanel
        header={<h2>GEM Analytics</h2>}
        footer={
          <>
          </>
        }
    >
      <p>
        This solution demonstrates GEM Analytics.
      </p>
    </HelpPanel>
);

// The content in the main content area of the App layout
export function SignUpContent() {

  const history = useHistory();

  const { id } = useParams();
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = React.useState("");

  // const [user, setUser] = React.useState("");
  // const [password, setPassword] = React.useState("");
  // const [confirmPassword, setConfirmPassword] = React.useState("");
  // const [firstName, setFirstName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");
  // const [phone, setPhone] = React.useState("");

  const [notifications, setNotifications] = useState([]);

  const approved_participants = ["truist.com", "amazon.com"]

  const addNotification = (message) => {
    const list = []
    for (let notification of notifications) {
      list.push(notification)
    }
    list.push({
      type: 'error',
      content: message,
      statusIconAriaLabel: 'error',
      dismissLabel: 'Dismiss all messages',
      dismissible: true,
      onDismiss: () => setNotifications([]),
      id: uuid4(),
    });
    setNotifications(list);
  };

  const signup = () => {
    try {
      if (validateForm() && is_approved_participant(approved_participants, email)) {

        Auth.signUp({
              username: email,
              password: password,
              attributes: {
                email: email,
                phone_number: phone,
                given_name: firstName,
                family_name: lastName
              }
            }
        ).then((result) => {
          history.push("/ConfirmSignup");
        }).catch(error => {
          console.log("Got error in signup function");
          console.log(error);
          addNotification("Error during sign up.")
        });

      } else {
        addNotification("Not an approved participant.")
      }

    } catch (error) {
      console.log("Got error in signup function");
      console.log(error);
    }
  }

  const validateForm = () =>  {

    setEmailError("");
    setPhoneError("");
    setFirstNameError("");
    setLastNameError("");
    setPasswordError("");
    setPasswordConfirmationError("");

    if (email == "") {
      setEmailError("Email is required.")
    }

    if (phone == "") {
      setPhoneError("Phone is required.")
    }

    if (firstName == "") {
      setFirstNameError("First Name is required.")
    }

    if (lastName == "") {
      setLastNameError("Last Name is required.")
    }

    if (password == "") {
      setPasswordError("Password is required.")
    }

    if (passwordConfirmation == "") {
      setPasswordConfirmationError("Password confirmation is required.")
    }

    if (passwordConfirmationError == "" && password != passwordConfirmation) {
      setPasswordConfirmationError("Password confirmation does not match with password.")
    }

    if (emailError != "" || phoneError != "" || firstNameError != "" || lastNameError != "" || passwordError != "" || passwordConfirmationError != "") {
      return false;
    }
    else {
      return true;
    }
  }

  const confirmSignup = () => {

    history.push("/ConfirmSignUp");
  }

  return (
      <div>
        <Box margin={{ bottom: 'l' }}>
          <div className="back_ground_black">
            <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
              <Grid
                  gridDefinition={[
                    { colspan: { xl: 6, l: 5, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
                  ]}
              >
                <div className="text_white">
                  <SpaceBetween size="xl">
                    <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                      Payment Operations
                    </Box>
                    <Box variant="h3" fontWeight="bold">
                      <span className="text_white">
                        The Payment Operations Portal
                      </span>
                    </Box>
                    <Box>
                      <Button disabled="true" href="/Analytics" variant="primary">Open Dashboard</Button>
                    </Box>
                  </SpaceBetween>
                </div>

              </Grid>
            </Box>
          </div>
          <div className="border_black">
            <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
              <Grid
                  gridDefinition={[
                    { colspan: 6, offset: 3 }
                  ]}
              >
                <div>
                  <Container
                      header={
                        <Header
                            variant="h1"
                            description="Please provide registration details."
                            actions={
                              <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" onClick={({ detail }) => signup()}>Register</Button>
                              </SpaceBetween>
                            }
                        >Registration</Header>
                      }
                  >
                    <SpaceBetween direction="vertical" size="xxl">
                      <SpaceBetween direction="vertical" size="s">
                        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 } ]}>
                          <FormField label="Email" stretch={true} errorText={emailError}>
                            <Input
                                value={email}
                                onChange={({detail}) => setEmail(detail.value) }
                                placeholder="xyz@amazon.com or xyz@truist.com"
                            />
                          </FormField>
                          <FormField label="Phone" stretch={true} errorText={phoneError}>
                            <Input
                                value={phone}
                                onChange={({detail}) => setPhone(detail.value)}
                                placeholder="+12015671234"
                            ></Input>
                          </FormField>
                        </Grid>
                        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                          <FormField label="First Name" stretch={true} errorText={firstNameError}>
                            <Input
                                value={firstName}
                                onChange={({detail}) => setFirstName(detail.value)}
                                placeholder="First Name"
                            />
                          </FormField>
                          <FormField label="Last Name" stretch={true} errorText={lastNameError}>
                            <Input
                                value={lastName}
                                onChange={({detail}) => setLastName(detail.value)}
                                placeholder="Last Name"
                            />
                          </FormField>

                        </Grid>
                        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 } ]}>
                          <FormField label="Password" stretch={true} errorText={passwordError}>
                            <Input
                                value={password}
                                onChange={({detail}) => setPassword(detail.value) }
                                placeholder="Password"
                                type="password"
                            />
                          </FormField>
                          <FormField label="Confirm Password" stretch={true} errorText={passwordConfirmationError}>
                            <Input
                                value={passwordConfirmation}
                                onChange={({detail}) => setPasswordConfirmation(detail.value) }
                                placeholder="Confirm Password"
                                type="password"
                            />
                          </FormField>
                        </Grid>
                      </SpaceBetween>
                    </SpaceBetween>
                  </Container>
                  <Box margin={{ top: 's', bottom: 'xl' }} padding={{ top: 'xl',  horizontal: 'xl' }}>
                    <SpaceBetween size="xl">
                      <div>
                        <Flashbar items={notifications} />
                      </div>

                    </SpaceBetween>
                  </Box>
                </div>

              </Grid>
            </Box>
            <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
            </Box>
          </div>
        </Box>

      </div>
  );
}

function is_approved_participant(participants, participant) {

  for (let item of participants) {

    if (participant.endsWith(item)) {

      return true;
    }
  }

  return false;
}

