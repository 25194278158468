// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import { CollectionPreferences, Link, StatusIndicator } from '@cloudscape-design/components';
import { addColumnSortLabels } from '../../common/labels';

export const COLUMN_DEFINITIONS = addColumnSortLabels([
  {
    id: 'date',
    sortingField: 'date',
    header: 'Date',
    cell: item =>  item.date,
    minWidth: 100,
  },
  {
    id: 'customer',
    sortingField: 'customer',
    header: 'Customer',
    cell: item => item.customer,
    minWidth: 100,
  },
  {
    id: 'account',
    sortingField: 'account',
    header: 'Account',
    cell: item =>  item.account,
    minWidth: 100,
  },
  {
    id: 'amount',
    sortingField: 'amount',
    header: 'Amount',
    cell: item =>  item.amount,
    minWidth: 100,
  },
  {
    id: 'beneficiary',
    sortingField: 'beneficiary',
    header: 'Beneficiary',
    cell: item =>  item.beneficiary,
    minWidth: 100,
  },
  {
    id: 'status',
    sortingField: 'status',
    header: 'Status',
    cell: item => item.status,
    minWidth: 100,
  }
  // {
  //   id: 'confirmation',
  //   sortingField: 'confirmation',
  //   header: 'Confirmation',
  //   cell: item =>  item.confirmation,
  //   minWidth: 100,
  // }
]);

const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Security guidance properties',
    options: [
      { id: 'date', label: 'Date', editable: true },
      { id: 'customer', label: 'Customer', editable: true },
      { id: 'account', label: 'Account', editable: true },
      { id: 'amount', label: 'Amount', editable: true },
      { id: 'beneficiary', label: 'Beneficiary', editable: true },
      { id: 'status', label: 'Status', editable: true }
      // { id: 'confirmation', label: 'Confirmation', editable: true }
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Rows' },
  { value: 25, label: '25 Rows' },
  { value: 50, label: '50 Rows' }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [ 'date', 'customer', 'account', 'amount', 'beneficiary', 'status'],
  wrapLines: true,
};

export const FILTERING_PROPERTIES = [
  {
    propertyLabel: 'Date',
    key: 'date',
    groupValuesLabel: 'Date values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Customer',
    key: 'customer',
    groupValuesLabel: 'Customer values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Account',
    key: 'account',
    groupValuesLabel: 'Account values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Amount',
    key: 'amount',
    groupValuesLabel: 'amount values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Beneficiary',
    key: 'beneficiary',
    groupValuesLabel: 'Beneficiary values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status values',
    operators: [':', '!:', '=', '!='],
  }
  // {
  //   propertyLabel: 'Confirmation',
  //   key: 'confirmation',
  //   groupValuesLabel: 'Confirmation values',
  //   operators: [':', '!:', '=', '!='],
  // }
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: text => `Use: "${text}"`,
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: 'Wrap lines',
      description: 'Check to see all the text and wrap the lines',
    }}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
  />
);
