// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React, { useEffect, useState } from 'react';
import PropertyFilter from "@cloudscape-design/components/property-filter";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { COLUMN_DEFINITIONS, FILTERING_PROPERTIES, PROPERTY_FILTERING_I18N_CONSTANTS, DEFAULT_PREFERENCES, Preferences } from './runbook-detail-table-config';
import {
  BreadcrumbGroup,
  Button,
  HelpPanel,
  Pagination,
  SpaceBetween,
  Table,
  Grid,
  Box
} from '@cloudscape-design/components';
import { CustomAppLayout } from '../common/app-layout';
import { Navigation } from '../common/navigation';
import { Notifications } from '../common/notifications';
import { TableEmptyState, TableHeader, TableNoMatchState } from '../common/table-components';

import { paginationLabels, distributionSelectionLabels } from '../../common/labels';
import { getFilterCounterText } from '../../common/tableCounterStrings';
import '../../styles/base.scss';
import { useColumnWidths } from '../common/use-column-widths';
import { useLocalStorage } from '../../common/localStorage';
// import { IExecution, ReduxRoot } from "../../interfaces";

import { useDispatch, useSelector } from "react-redux";
import { storeExecution } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import {getRunbookExecutionDetails, getRunbookExecutions} from "../../data";
import {ReduxRoot} from "../../interfaces";

export const resourcesBreadcrumbs = [
  {
    text: 'Runbooks',
    href: '/Runbooks',
  },
  {
    text: 'Runbook',
    href: '/Runbook',
  }
];

export const Breadcrumbs = () => (
    <BreadcrumbGroup items={resourcesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export const FullPageHeader = ({
                                 resourceName = 'Runbook',
                                 createButtonText = 'Create Runbook',
                                 ...props
                               }) => {
  const isOnlyOneSelected = props.selectedItems.length === 1;

  const history = useHistory();

  return (
      <TableHeader
          variant="awsui-h1-sticky"
          title={resourceName}
          {...props}
      />
  );
};

export const ToolsContent = () => (
    <HelpPanel
        header={<h2>Runbook Execution</h2>}
        footer={
          <>
          </>
        }
    >
      <p>
        View Steps In A Runbook Execution.
      </p>
    </HelpPanel>
);

function TableContent() {

  const dispatch = useDispatch();

  const token = useSelector( (state) => {
    return state.reducerState.token
  });

  const execution = useSelector( (state) => {
    return state.reducerState.execution
  });

  const [steps, setSteps] = useState([]);
  const [selectedSteps, setSelectedSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState({});
  const [selectedStepNumber, setSelectedStepNumber] = React.useState(0);

  const [columnDefinitions, saveWidths] = useColumnWidths('React-Table-Widths', COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage('React-DistributionsTable-Preferences', DEFAULT_PREFERENCES);

  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
      steps,
    {
      propertyFiltering: {
        filteringProperties: FILTERING_PROPERTIES,
        empty: <TableEmptyState resourceName="Steps" />,
        noMatch: (
            <TableNoMatchState
                onClearFilter={() => {
                  actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                }}
            />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
      selection: {},
    }
  );

  const getExecutionDetails = async () => {

    try {

      await getRunbookExecutionDetails(token, execution.automation_execution_id).then(
          (result) => {
            setSteps(result.steps);
            console.log("Getting Selected Step Number: " + selectedStepNumber)
            // if (selectedStepNumber > 0) {
            //   setSelectedStep(steps[selectedStepNumber])
            // }
            setSelectedSteps([selectedStep]);
          });

      await Promise.resolve();

    }
    catch (err) {
      console.log("Got Error Message: " + err.toString());
    }
  }

  useEffect( () => {

    getExecutionDetails().then(() => console.log("getExecutionDetails() completed."));
    const interval = setInterval(() => {
      getExecutionDetails().then(() => console.log("getExecutionDetails() completed."));
      console.log("Getting Selected Step Number: " + selectedStepNumber)
      // if (selectedStepNumber > 0) {
      //   setSelectedStep(steps[selectedStepNumber])
      // }
      setSelectedSteps([selectedStep]);
    }, 5000);
  }, []);

  const handleSelectionChange = (items) => {
    if (items.length === 1) {
      const step_number = Number(items[0].step_number?items[0].step_number:0)
      setSelectedStepNumber(step_number)
      setSelectedStep(items[0])
      console.log("Setting Selected Step Number: " + selectedStepNumber)
    }
  };

  const renderInput = (value) => {
    return Object.entries(JSON.parse(value)).map((item) => {
      return (
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 } ]}>
              <Box margin={{ top: "n", bottom: "n"}} padding={{ top: "n", bottom: "n" }}>
                {item[0]}
              </Box>
              <Box margin={{ top: "n", bottom: "n"}} padding={{ top: "n", bottom: "n" }}>
                : {item[1]}
              </Box>
            </Grid>
      );
    });
  }

  const renderLog = (value) => {
    value = value!=""?value.slice(1, -1):""
    const lines = value.split("\\n")
    return lines.map((line) => {
      return (
            <Grid gridDefinition={[{ colspan: 12 }]}>
              <Box margin={{ top: "n", bottom: "n"}} padding={{ top: "n", bottom: "n" }}>
                {line.replace("\\n", "")}
              </Box>
            </Grid>
      );
    });
  }

  return (
      <div>
        <div className="border_black">
          <Box container xs={12}>
            <Table
              {...collectionProps}
              items={items}
              columnDefinitions={columnDefinitions}
              visibleColumns={preferences.visibleContent}
              ariaLabels={distributionSelectionLabels}
              selectionType="single"
              variant="full-page"
              stickyHeader={true}
              resizableColumns={true}
              wrapLines={preferences.wrapLines}
              onColumnWidthsChange={saveWidths}
              header={
                <FullPageHeader
                  selectedItems={selectedSteps}
                  totalItems={steps}
                  serverSide={false}
                />
              }
              loadingText="Loading steps"
              filter={
                <PropertyFilter
                  i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                  {...propertyFilterProps}
                  countText={getFilterCounterText(filteredItemsCount)}
                  expandToViewport={true}
                />
              }
              pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
              preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
              selectedItems={selectedSteps}
              onSelectionChange={evt => {setSelectedSteps(evt.detail.selectedItems); handleSelectionChange(evt.detail.selectedItems)}}
            />
          </Box>
        </div>
        <Grid disableGutters gridDefinition={[{ colspan: 12 } ]}>

          <div className="border_black">
            <Grid disableGutters gridDefinition={[{ colspan: 6 }, { colspan: 6 } ]}>
              <div>
                <div className="spacing_5"><b>Input</b></div>
                <div className="spacing_5">{selectedStep.input_payload?renderInput(selectedStep.input_payload?selectedStep.input_payload:{}):""}</div>
              </div>

              <div>
                <div className="spacing_5"><b>Output</b></div>
                <div className="spacing_5">{selectedStep.output_payload?renderInput(selectedStep.output_payload?selectedStep.output_payload:{}):""}</div>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid disableGutters gridDefinition={[{ colspan: 12 } ]}>
          <div className="border_black">
            <Grid disableGutters gridDefinition={[{ colspan: 12 } ]}>
              <div>
                <div className="spacing_5"><b>Execution Log</b></div>
                <div className="spacing_5">{selectedStep.execution_log?renderLog(selectedStep.execution_log?selectedStep.execution_log:""):""}</div>
              </div>
            </Grid>
          </div>
        </Grid>

      </div>
  );
}

function RunbookDetailTableView() {
  const [columnDefinitions, saveWidths] = useColumnWidths('React-TableServerSide-Widths', COLUMN_DEFINITIONS);
  const [toolsOpen, setToolsOpen] = useState(false);

  return (
      <CustomAppLayout
          navigation={<Navigation activeHref="/Runbook" />}
          notifications={<Notifications successNotification={false} />}
          breadcrumbs={<Breadcrumbs />}
          content={
            <TableContent
                columnDefinitions={columnDefinitions}
                saveWidths={saveWidths}
                updateTools={() => setToolsOpen(true)}
            />
          }
          contentType="table"
          tools={<ToolsContent />}
          toolsOpen={toolsOpen}
          // onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          stickyNotifications={true}
      />
  );
}

export default RunbookDetailTableView;