// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import {IAnalytics, ICustomer, ICustomerFile, IExecution, IPayment} from "../../interfaces";

export enum ActionTypes {
    STORE_TOKEN = "STORE_TOKEN",
    STORE_ANALYTICS = "STORE_ANALYTICS",
    STORE_CUSTOMER = "STORE_CUSTOMER",
    STORE_CUSTOMER_FILE = "STORE_CUSTOMER_FILE",
    STORE_EXECUTION = "STORE_EXECUTION",
    STORE_PAYMENT = "STORE_PAYMENT"
}

export const storeToken = (token:string) => ({
    type: ActionTypes.STORE_TOKEN, token
})

export const storeAnalytics = (analytics:IAnalytics) => ({
    type: ActionTypes.STORE_ANALYTICS, analytics
})

export const storeCustomer = (customer:ICustomer) => ({
    type: ActionTypes.STORE_CUSTOMER, customer
})

export const storeCustomerFile = (customerFile:ICustomerFile) => ({
    type: ActionTypes.STORE_CUSTOMER_FILE, customerFile
})

export const storeExecution = (execution:IExecution) => ({
    type: ActionTypes.STORE_EXECUTION, execution
})

export const storePayment = (payment:IPayment) => ({
    type: ActionTypes.STORE_PAYMENT, payment
})