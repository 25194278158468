// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React, {useEffect, useState} from 'react';

import '../common/styles.css';
import '../../styles/base.scss';
import {
  Box,
  Button,
  Container,
  Grid,
  HelpPanel,
  SpaceBetween,
  BreadcrumbGroup
} from "@cloudscape-design/components";
import {Navigation} from "../common/navigation";
import {CustomAppLayout} from "../common/app-layout";
import Cognito from "../home/cognito";
import {useHistory} from "react-router-dom";

import primary_image from "./pay-primary.jpg";
import secondary_image from "./pay-secondary.jpg";
import none_image from "./pay-none.jpg";
import {IAppControls} from "../../interfaces"

import {executeRunbook, getAppControls} from "./../../data"
import { useSelector } from "react-redux";
import { ReduxRoot } from "../../interfaces";

export default class SummaryView extends React.Component {
  render() {
    return (
        <CustomAppLayout
            navigation={<Navigation activeHref="/Summary"/>}
            // navigationOpen={true}
            breadcrumbs={<Breadcrumbs />}
            content={<SummaryContent />}
            contentType="default"
            tools={<ToolsContent />}
            // toolsOpen={false}
        />
    );
  }
}

export const resourcesBreadcrumbs = [
  {
    text: 'Summary',
    href: '/Summary',
  }
];

export const Breadcrumbs = () => (
    <BreadcrumbGroup items={resourcesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export const ToolsContent = () => (
    <HelpPanel
        header={<h2>Summary View</h2>}
        footer={
          <>
          </>
        }
    >
      <p>
        The Summary View of Payment Operations Portal.
      </p>
    </HelpPanel>
);

// The content in the main content area of the App layout
export function SummaryContent() {

  const token = useSelector( (state:ReduxRoot) => {
    return state.reducerState.token
  });

  const [appControls, setAppControls] = useState<IAppControls>({});

  const history = useHistory();

  const getAllRequests = async () => {

    try {
      let request = await getAppControls(token).then(
          (result) => {
            console.log("Received Result for getAppControls for Trade Matching for Primary:" + JSON.stringify(result))
            setAppControls(result);
          });
    } catch (err) {
    }
  }

  const renderImage = (controls: IAppControls) => {
    return ((controls.primary_control_state == "On"?<img src={primary_image} width="900" height="450"/>:(controls.secondary_control_state == "On"?<img src={secondary_image} width="900" height="450"/>:<img src={none_image} width="900" height="450"/>)));
  }

  useEffect( () => {

    console.log("useEffect Called")

    getAllRequests().then(() => console.log("getAppState() completed."));
  }, []);

  const executeSwitchoverRunbook = async () => {

    try {
      let request = await executeRunbook(token, "Switchover-Runbook").then(
          (result) => {
            console.log("Execute Switchover Runbook" + JSON.stringify(result))
          });
    } catch (err) {
    }
  }

  const executeFailoverRunbook = async () => {

    try {
      let request = await executeRunbook(token, "Failover-Runbook").then(
          (result) => {
            console.log("Execute Failover Runbook" + JSON.stringify(result))
          });
    } catch (err) {
    }
  }

  return (
      <div>
        <div>
          <Box padding={{ vertical: 'xl', horizontal: 's' }}>
            <Grid
                gridDefinition={[
                  { colspan: { xl: 6, l: 5, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
                ]}
            >
              <div className="text_white">
                  <Grid
                      gridDefinition={[
                        { colspan: 4, offset: 4 }
                      ]}
                  >
                      <div>
                        <Button onClick={({ detail }) => executeSwitchoverRunbook()} variant="primary">Switchover</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button onClick={({ detail }) => executeFailoverRunbook()} variant="primary">Failover</Button>
                      </div>
                  </Grid>
              </div>

            </Grid>
          </Box>
        </div>
        <Box margin={{ bottom: 'l' }}>
          <div>
            <Box margin={{ top: 's' }} padding={{ top: 's', horizontal: 's' }}>
              <Grid
                  gridDefinition={[
                    { colspan: 12, offset: 0 }
                  ]}
              >
                <div>
                  <SpaceBetween size="l">
                    <div>
                      <Container>
                        {renderImage(appControls)}
                      </Container>
                    </div>

                  </SpaceBetween>
                </div>

              </Grid>
            </Box>
            <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
            </Box>
          </div>
        </Box>
        <Cognito/>
      </div>
  );
}



