// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

export const DASHBOARD_ENDPOINTS = {
    ApiKey: '',
    Endpoint: 'ADMIN_DASHBOARD_ENDPOINT_PLACEHOLDER',
    Resources : [ "get-customer-input-files", "get-customer-output-files", "get-file-content", "put-file-content", "update-password" ]
};

export const OPERATIONS_API_ENDPOINT = {
    ApiKey: '',
    Endpoint: 'https://vxtnovmms4.execute-api.us-east-1.amazonaws.com/dev',
    Resources : [ "get-app-controls", "execute-runbook", "get-runbook-executions", "get-runbook-execution-detail", "search-payments-by-customer", "search-payments-by-customer-reference", "search-payment-by-id" ]
};
