// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: LicenseRef-.amazon.com.-AmznSL-1.0
// Licensed under the Amazon Software License  http://aws.amazon.com/asl/

import React from 'react';
import { CollectionPreferences, Link, StatusIndicator } from '@cloudscape-design/components';
import { addColumnSortLabels } from '../../common/labels';

export const AUDIT_COLUMN_DEFINITIONS = addColumnSortLabels([
  {
    id: 'trace',
    sortingField: 'trace',
    header: 'Trace',
    cell: item =>  item.trace,
    minWidth: 150,
  },
  {
    id: 'status',
    sortingField: 'status',
    header: 'Status',
    cell: item => item.status,
    minWidth: 150,
  },
  {
    id: 'time',
    sortingField: 'time',
    header: 'Time',
    cell: item =>  item.time,
    minWidth: 300,
  }
]);

export const AUDIT_VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Security guidance properties',
    options: [
      { id: 'trace', label: 'Trace', editable: true },
      { id: 'status', label: 'Status', editable: true },
      { id: 'time', label: 'Time', editable: true }
    ],
  },
];

export const AUDIT_PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Rows' },
  { value: 25, label: '25 Rows' },
  { value: 50, label: '50 Rows' }
];

export const AUDIT_DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [ 'trace', 'status', 'time'],
  wrapLines: true,
};

export const AUDIT_FILTERING_PROPERTIES = [
  {
    propertyLabel: 'Trace',
    key: 'trace',
    groupValuesLabel: 'Trace values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Time',
    key: 'time',
    groupValuesLabel: 'Time values',
    operators: [':', '!:', '=', '!='],
  }
];

export const AUDIT_PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',

  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',

  operationAndText: 'and',
  operationOrText: 'or',

  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',

  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',

  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: text => `Use: "${text}"`,
};

export const AuditPreferences = ({
                              preferences,
                              setPreferences,
                              disabled,
                              pageSizeOptions = AUDIT_PAGE_SIZE_OPTIONS,
                              visibleContentOptions = AUDIT_VISIBLE_CONTENT_OPTIONS,
                            }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        pageSizePreference={{
          title: 'Page size',
          options: pageSizeOptions,
        }}
        wrapLinesPreference={{
          label: 'Wrap lines',
          description: 'Check to see all the text and wrap the lines',
        }}
        visibleContentPreference={{
          title: 'Select visible columns',
          options: visibleContentOptions,
        }}
    />
);
